
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      visibleUserOffcanvasMenu: 'visibleUserOffcanvasMenu'
    })
  },

  methods: {
    goToAnchor(anchor) {
      this.$store.dispatch('setVisibleUserOffcanvasMenu', false)
      this.$scrollTo(anchor, 800, { offset: 0 })
    }
  }
}
